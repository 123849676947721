import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Menu, MenuItem, IconButton as MuiIconButton, Typography } from "@mui/material";

import styled from "@emotion/styled";
import { jwtDecode } from "jwt-decode";
import { omit } from "lodash.omit";

import { useAuth } from "../../services/auth/AuthProvider";
import { updateUser } from "../../store/actions";
import { selectAllUsersByUsersId } from "../../store/selectors";
import "../../styles/perfect-scrollbar.css";
import { getLanguages } from "../../utils/common";
import { formatLanguage } from "../../utils/helpers";
import { SmallAvatar } from "../styledComponents/SmallAvatar";

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
        color: white;
        margin-right: 5px;
        margin-left: 5px;
    }
    color: white;
    font-size: 18px;
`;

const Flag = styled.img`
    border-radius: 50%;
    width: 30px;
    height: 30px;
`;

function LanguagesMenu() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [anchorMenu, setAnchorMenu] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("i18nextLng"));
    const languages = getLanguages();
    const { auth } = useAuth();
    const { userid } = jwtDecode(auth.access_token); // get current usersid from jwt
    const currentUser = useSelector((state) => selectAllUsersByUsersId(state, Number(userid)));

    const toggleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleMenuItemClick = (_event, language) => {
        setSelectedLanguage(language);

        // update language in the browser
        i18n.changeLanguage(language);
        // update user language in the db
        dispatch(
            updateUser(
                {
                    ...omit(currentUser, ["defapps", "isAdmin", "status", "fullName"]),
                    admin: currentUser.isAdmin,
                    languagecode: formatLanguage(language, false),
                },
                false,
            ),
        );
        // close menu
        setAnchorMenu(null);
    };
    const closeMenu = () => {
        setAnchorMenu(null);
    };

    return (
        <Fragment>
            <IconButton
                name={"languageButton"}
                aria-owns={anchorMenu ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={toggleMenu}
                color="inherit"
                style={{ boxShadow: "none" }}
                size="small"
            >
                <Flag name={"flag"} src={`/flags/${selectedLanguage}.png`} alt={selectedLanguage} />
            </IconButton>

            <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
                {languages.map((lang) => {
                    return (
                        <MenuItem
                            style={{ minWidth: "180px" }}
                            name={lang}
                            key={lang}
                            selected={lang === selectedLanguage}
                            onClick={(event) => handleMenuItemClick(event, lang)}
                        >
                            <Grid container direction="row">
                                <Grid item xs={3}>
                                    {lang === "nl" && <SmallAvatar src="/flags/nl.png" alt="Dutch" />}
                                    {lang === "en" && <SmallAvatar src="/flags/en.png" alt="English" />}
                                    {lang === "de" && <SmallAvatar src="/flags/de.png" alt="German" />}
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography>{t(`headerComponent.languages.${lang}`)}</Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Fragment>
    );
}

export default LanguagesMenu;
