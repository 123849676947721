/*
Dit is de NieuwDialoog component.Op deze pagina kan een gebruiker een nieuwe gebruiker aanmaken. Deze pagina wordt vanuit de gebruikers pagina
aangeroepen. Om te verzorgen dat de ingevoerde gegevens zo volledig mogelijk is, wordt er een controle gedaan op de velden: naam, achternaam,
email, wachtwoord deze zijn verplichte velden. eenmaal dat deze gevuld zijn wordt de gebruiker aangemaakt.
*/
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import { capitalize } from "lodash.capitalize";

import CustomDialog from "../../../components/CustomDialog";
import { DEFAULT_EXPDATE_STRING } from "../../../constants/users";
import { USER_PATHS } from "../../../routes/paths/users";
import { createUser } from "../../../store/actions";
import { formatLanguage } from "../../../utils/helpers";
import { getUserValidationScheme } from "../../../validation/users";
import Content from "./content";

function Create({ createUser }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            usersid: 0,
            isUserChoice: true,
            isTempUser: false,
            totp: false,
            admin: false,
            loginemail: "",
            password: "",
            infixname: "",
            lastname: "",
            firstname: "",
            languagecode: localStorage.getItem("i18nextLng"),
            expdate: DEFAULT_EXPDATE_STRING,
            showPassword: false,
            isValidPassword: false,
        },
        validationSchema: getUserValidationScheme(),
        onSubmit: () => {
            handleSubmit();
        },
    });
    const { values } = formik;

    //sluit nieuw gebruiker dialoog
    const handleClose = () => {
        //redirect naar de gebruikers
        navigate(USER_PATHS.DEFAULT_PATH);
    };

    //voeg nieuw gebruiker toe aan de database
    const handleSubmit = () => {
        // stuur gegevens naar api

        createUser({
            usersid: 0,
            firstname: capitalize(values.firstname),
            infixname: values.infixname,
            lastname: capitalize(values.lastname),
            loginemail: values.loginemail,
            loginpassword: values.password,
            languagecode: formatLanguage(values.languagecode, false),
            admin: values.admin,
            expdate: values.isTempUser && values.expdate ? values.expdate : DEFAULT_EXPDATE_STRING,
            totp: values.totp,
        });

        //sluit de dialoog
        handleClose();
    };

    return (
        <CustomDialog
            title={t("userComponent.dialog.dialogTitleC")}
            id="newUserDialog"
            draggable={true}
            animated={true}
            maxWidth="md"
            closeWithIcon={true}
            fullWidth={true}
            open={true}
            handleClose={handleClose}
            handleSubmit={formik.submitForm}
            defaultButtons={[
                {
                    label: t("profileComponent.dialog.dialogActionC"),
                    isPrimary: false,
                    isSubmit: false,
                },
                {
                    label: t("userComponent.dialog.dialogActionA"),
                    isPrimary: true,
                    isSubmit: true,
                },
            ]}
            content={<Content formik={formik} />}
        />
    );
}

const mapDispatchToProps = {
    createUser,
};

export default connect(null, mapDispatchToProps)(Create);
