/*
Dit is de create component. Op deze pagina kan een gebruiker/beheerder een nieuw profiel maken. Om te zorgen dat een gebruiker/beheerder
niet een leeg profiel kan toevoegen, wordt er een controle gedaan op de omschrijving. Ook is het mogelijk om een default profiel settings te kiezen als uitgnagspunt.
*/
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid, MenuItem, TextField } from "@mui/material";

import { useFormik } from "formik";

import CustomDialog from "../../components/CustomDialog";
import { RITPLAN } from "../../constants/general";
import { INTEGRATION_PATHS } from "../../routes/paths/integrations";
import { createIntegration, fetchDefapps, fetchVersions } from "../../store/actions";
import { selectAllMenuDefapps, selectAllVersionsByDefappsId } from "../../store/selectors";
import { getIntegrationValidationScheme } from "../../validation/integration";
import ShowKeyDialog from "./ShowKey";

function Create({ createIntegration, fetchDefapps, fetchVersions }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const menuApps = useSelector((state) => selectAllMenuDefapps(state));
    const ritplan = menuApps.find((app) => app.code === RITPLAN)?.defappsid;
    const versions = useSelector((state) => selectAllVersionsByDefappsId(state, ritplan ?? 0)) ?? [];

    const formik = useFormik({
        initialValues: {
            description: "",
            apikey: "",
            application: "",
            showKey: false,
            licversionsid: "",
            username: "",
        },
        validationSchema: getIntegrationValidationScheme(),
        onSubmit: () => {
            handleSubmit();
        },
    });

    //sluit nieuw API dialoog
    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
        navigate(INTEGRATION_PATHS.DEFAULT_PATH);
    };

    //voeg nieuw API toe aan de database
    const handleSubmit = async () => {
        const resp = await createIntegration({
            apikeysid: 0,
            aud: formik.values.application,
            username: formik.values.username,
            licversionsid: Number(formik.values.licversionsid),
            description:
                formik.values.description.slice(0, 1).toUpperCase() +
                formik.values.description.slice(1, formik.values.description.length),
        });
        if (resp) {
            formik.setValues((values) => ({
                ...values,
                showKey: true,
                apikey: resp,
            }));
        }
    };

    useEffect(() => {
        if (versions.length === 1) {
            formik.setValues((values) => ({
                ...values,
                licversionsid: versions?.[0]?.licversionsid,
            }));
        }
    }, [versions]);

    useEffect(() => {
        if (menuApps.length !== 0 && versions.length === 0) {
            fetchVersions(ritplan);
        }
    }, [menuApps]);

    useEffect(() => {
        if (menuApps.length === 0) {
            fetchDefapps();
        }
    }, []);

    return (
        <>
            <CustomDialog
                id="nieuwApiDialoog"
                animated={true}
                closeWithIcon={true}
                draggable={true}
                title={t("integrationComponent.dialog.title")}
                open={open}
                maxWidth="sm"
                fullWidth={true}
                handleClose={handleClose}
                handleSubmit={formik.submitForm}
                defaultButtons={[
                    {
                        label: t("profileComponent.dialog.dialogActionC"),
                        isPrimary: false,
                        isSubmit: false,
                    },
                    {
                        label: t("integrationComponent.dialog.buttons.generate"),
                        isPrimary: true,
                        isSubmit: true,
                    },
                ]}
                content={
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="description"
                                name="description"
                                label={t("integrationComponent.dialog.description")}
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                onBlur={formik?.handleBlur}
                                error={formik?.touched["description"] && Boolean(formik.errors["description"])}
                                helperText={formik.touched["description"] && formik.errors["description"]}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                select
                                margin="dense"
                                id="application"
                                name="application"
                                label={t("integrationComponent.dialog.application")}
                                onBlur={formik?.handleBlur}
                                error={formik?.touched["application"] && Boolean(formik.errors["application"])}
                                helperText={formik.touched["application"] && formik.errors["application"]}
                                onChange={formik.handleChange}
                                value={formik.values.application}
                                size="small"
                            >
                                <MenuItem disabled value={"0"}>
                                    <em>{t("headerComponent.menuDefault")}</em>
                                </MenuItem>
                                <MenuItem value={"RP9"}>Ritplan</MenuItem>
                                <MenuItem value={"API"}>{t("integrationComponent.dialog.other")}</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="licversionsid"
                                select
                                type="number"
                                name="licversionsid"
                                label={t("integrationComponent.dialog.version")}
                                helperText={formik.touched["licversionsid"] && formik.errors["licversionsid"]}
                                onChange={formik.handleChange}
                                value={formik.values.licversionsid}
                                onBlur={formik?.handleBlur}
                                error={formik?.touched["licversionsid"] && Boolean(formik.errors["licversionsid"])}
                                size="small"
                            >
                                <MenuItem disabled value={"0"}>
                                    <em>{t("integrationComponent.dialog.menuDefault")}</em>
                                </MenuItem>
                                {versions.map((vrs) => {
                                    return (
                                        <MenuItem key={vrs.description} value={vrs.licversionsid}>
                                            {vrs.description}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        {formik.values.application === "API" && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="username"
                                    name="username"
                                    label={t("integrationComponent.dialog.username")}
                                    onChange={formik.handleChange}
                                    value={formik.values.username}
                                    onBlur={formik?.handleBlur}
                                    error={formik?.touched["username"] && Boolean(formik.errors["username"])}
                                    helperText={formik.touched["username"] && formik.errors["username"]}
                                    size="small"
                                />
                            </Grid>
                        )}
                    </Grid>
                }
            />

            {/* Toon de nieuwe Api key */}
            <ShowKeyDialog
                open={formik.values.showKey}
                apikey={formik.values.apikey}
                close={() => formik.resetForm()}
            />
        </>
    );
}
const mapDispatchToProps = {
    createIntegration,
    fetchDefapps,
    fetchVersions,
};
export default connect(null, mapDispatchToProps)(Create);
