import { t } from "i18next";

import { DELETE_USER, SUCCESS, UPDATE_USER, UPSERT_USER } from "../../constants/redux";
import apiService from "../../services/api/apiService";
import { handleErrors } from "../../utils/helpers";

// haal alle gebruikers op
export const fetchUsers = () => async (dispatch) => {
    try {
        const response = await apiService.get(`appmanagement/users`, {
            Top: 5000,
        });

        dispatch({
            type: UPSERT_USER,
            payload: response.data,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// maak nieuwe gebruiker aan
export const createUser = (user) => async (dispatch) => {
    try {
        const createResponse = await apiService.post(`appmanagement/users`, user);
        dispatch(fetchUsers());
        dispatch({
            type: SUCCESS,
            payload: t("userComponent.messages.create") + ` - ${createResponse.data.value}`,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// gebruiker wijzigen
export const updateUser = (user, showSuccessMessage) => async (dispatch) => {
    try {
        const updateResponse = await apiService.post(`appmanagement/users`, user);

        dispatch({
            type: UPDATE_USER,
            payload: user,
        });
        {
            showSuccessMessage &&
                dispatch({
                    type: SUCCESS,
                    payload: t("userComponent.messages.update") + ` - ${updateResponse.data.value}`,
                });
        }
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};

// verwijderd gebruiker
export const deleteUser = (id) => async (dispatch) => {
    try {
        await apiService.delete(`appmanagement/users`, {
            usersid: id,
        });

        // verwijderd alleen de geselecteerde gebruiker in de orm
        dispatch({
            type: DELETE_USER,
            payload: id,
        });
        dispatch({
            type: SUCCESS,
            payload: t("userComponent.messages.delete") + ` - ${id}`,
        });
    } catch (error) {
        // toon foutmelding
        dispatch(handleErrors(error));
    }
};
